import { CustomerServiceEndpoints } from "./HttpConstants";
import { CUSTOMER_MICROSERVICE_BASE_URL } from "../Shared/Configs";

const UNIVERSALPRACTICE_KITTYPE=30000;

export async function updateCustomerDetails(store) {
  //Adding UniversalPracticeUser as the user as DynamoDb needs last name and the call fails.
  let customerDetails = {
    lastName: 'UniversalPracticeUser',
    emailAddress: store.auth.user.username,
    timeZone: store.userDetails.timeZone,
    promoCode: store?.couponDetails?.promoCode ?? null,
    subscriptionCode: store?.initialValue.subscription.subscriptionOptionId,
    recurringPaymentPriceId: store?.initialValue?.subscription?.subscriptionOptionPriceId
  }
  return callUpdateRegistrationDetails(customerDetails);
}

async function callUpdateRegistrationDetails(customerDetails) {
  return fetch(
    `${CUSTOMER_MICROSERVICE_BASE_URL}${CustomerServiceEndpoints.UpdateRegistrationDetails}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(customerDetails),
    }
  );
}

export async function commitCustomerDetails(store) {
  let customerDetails = {
    emailAddress: store.auth.user.username,
    timeZone: store.userDetails.timeZone,
    promoCode: store?.couponDetails?.promoCode ?? null,
    subscriptionCode: store?.initialValue.subscription.subscriptionOptionId,
    addressLine1: store?.initialValue.payment.addressLine1,
    suburbCity: store?.initialValue.payment.suburbCity,
    stateProvince: store?.initialValue.payment.stateProvince,
    postalCode: store?.initialValue.payment.postalCode,
    firstName: store?.initialValue.payment.firstName,
    lastName: store?.initialValue.payment.lastName,
    country: 'Australia',
    paymentMethodId: store?.initialValue.payment.paymentMethodId,
    recurringPaymentPriceId: store?.initialValue?.subscription?.subscriptionOptionPriceId
  }
  return callCommitRegistrationDetails(customerDetails);
}

async function callCommitRegistrationDetails(customerDetails) {
  return fetch(
    `${CUSTOMER_MICROSERVICE_BASE_URL}${CustomerServiceEndpoints.CommitRegistrationDetails}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(customerDetails),
    }
  );
}

export async function getSubscriptionDetails() {
  return fetch(`${CUSTOMER_MICROSERVICE_BASE_URL}/RegisterMicroService/Register/ProductSubscriptionFreightOptions?Country=Australia&Partner=Universal%20Practice`)
}

export async function verifyCoupon(promoCode, subscriptionCode) {
  return fetch(`${CUSTOMER_MICROSERVICE_BASE_URL}/RegistrationOptions/v2/VerifyPromoCode?partnerName=Universal%20Practice&promoCode=${promoCode}&subscriptionCode=${subscriptionCode}&kitType=${UNIVERSALPRACTICE_KITTYPE}`)
}