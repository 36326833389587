import React from "react";
import ConfirmSignUp from "aws-amplify-react/lib/Auth/ConfirmSignUp";
import { Auth } from "aws-amplify";
import {
  confirmSignUp,
  confirmSignUpFailure,
  signedIn,
  RegisterActions,
} from "../Actions/RegisterActions";
import { withStyles } from "@material-ui/core/styles";
import { AuthResult, AuthenticationTitle } from "../Shared/Constants";
import { messages } from "../Shared/Messages";
import { Redirect } from "react-router";
import ComponentButtons from "../Shared/ComponentButtons";
import ComponentContainerHeader from "../Shared/ComponentContainerHeader";
import { PrimaryTitles, SecondaryTitles } from "../Shared/TitleTexts";
import AuthenticationStepper from "../Shared/AuthenticationStepper";
import StyledTextField from "../components/StyledTextField.js";
const useStyles = (theme) => ({
  root: {
    fontFamily: "sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",

    "& .code-message": {
      fontSize: "16px",
      lineHeight: "20px",
      color: "#717073",
      paddingTop: "20px",
      paddingBottom: "10px",
    },
  },
});

class MyDnaConfirmSignUp extends ConfirmSignUp {
  constructor(props) {
    super(props);
    this._validAuthStates = [RegisterActions.CONFIRM_SIGN_UP];
    this.state = {
      errorMessage: null
    }
  }
  confirmSignUp(e) {
    e.preventDefault();
    const { username, password } = this.props.authData;
    if (!(/^[0-9]{1,6}$/.test(this.inputs.code))) {
      this.setState({
        errorMessage: messages.CodeMismatchException,
      });
      return
    }
    Auth.confirmSignUp(this.props.authData?.username, this.inputs.code)
      .then((data) => {
        if (data === AuthResult.SUCCESS) {
          Auth.signIn(username, password)
            .then((data) => {
              this.props.dispatch(signedIn(data));
              super.changeState(RegisterActions.SIGNED_IN);
            })
            .catch((err) => {
              super.changeState(RegisterActions.SIGN_IN);
            });
        }
      })
      .catch((err) => {
        if (err.code === "UserNotFoundException") {
          this.setState({
            errorMessage: messages.userNotFound,
          });
        } else if (err.code === "CodeMismatchException") {
          this.setState({
            errorMessage: messages.CodeMismatchException,
          });
        } else if (err.code === "confirmSignUp_failure") {
          this.setState({
            errorMessage: messages.signUpFailed,
          });
        } else {
          this.setState({
            errorMessage: err.message,
          });
          this.props.dispatch(confirmSignUpFailure(err.message));
        }
      });
  }

  resend() {
    Auth.resendSignUp(this.props.authData?.username)
      .then(() => this.props.dispatch(confirmSignUp(messages.codeResent)))
      .catch((err) => {
        this.setState({
          errorMessage: err.message,
        });
        this.props.dispatch(confirmSignUpFailure(err.message));
      });
  }

  showComponent(theme) {
    return this.props.auth.isAuthenticated ? (
      <Redirect to="/subscription" />
    ) : (
        <div >
          <AuthenticationStepper
            title={AuthenticationTitle.VerifySignUp.title}
            progress={AuthenticationTitle.VerifySignUp.progress}
          />
          <form onSubmit={(e) => this.confirmSignUp(e)} >
            <ComponentContainerHeader
              primaryTitle={PrimaryTitles.Verify}
              secondaryTitle={SecondaryTitles.Verify}
            />
            <div className={'content content-width'}>
              <div>
                <StyledTextField
                  id="code"
                  title="Verification Code *"
                  key="code"
                  name="code"
                  onChange={this.handleInputChange}
                  required={true}
                />
              </div>
              <div>
                {this.state.errorMessage && (
                  <div>
                    <p className="error">{this.state.errorMessage}</p>
                  </div>
                )}
                <br />
                <ComponentButtons
                  dispatch={this.props.dispatch}
                  previousPage={this.props.previousPage}
                  singleButton={false}
                  isSubmitting={false}
                  backOnClick={() => this.resend()}
                  disableArrows={true}
                  submitText={"VERIFY"}
                  backText={"RESEND CODE"}
                />
              </div>
            </div>
          </form>
        </div>
      );
  }
}

export default withStyles(useStyles)(MyDnaConfirmSignUp);
