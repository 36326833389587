import React from 'react'
import { CardCvcElement } from '@stripe/react-stripe-js';

const MyDNACardCvCElement = ({ onChange }) => {
    return (
        <div className="background">
            <CardCvcElement
                className="card-textfield"
                options={{
                    placeholder: '',
                    style: {
                        base: {
                            fontSize: '18px',
                            color: '#fff',
                            '::placeholder': {
                                color: '#fff',
                            },
                        },
                        invalid: {
                            color: '#9e2146',
                        },
                    },
                }}
            />
        </div>
    )
}

export default MyDNACardCvCElement
