import React from "react";

export const CardTypes = () => {
  return (
    <div>
      <img
        src={require("../Assets/Visa.png")}
        style={{ width: 60, height: 40, padding: 5 }}
        alt="Logo"
      />
      <img
        src={require("../Assets/mc_symbol.png")}
        style={{ width: 60, height: 40, padding: 5 }}
        alt="Logo"
      />
    </div>
  );
};
