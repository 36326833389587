import React, { Component } from "react";
import ComponentContainerHeader from "../Shared/ComponentContainerHeader";
import { PrimaryTitles } from "../Shared/TitleTexts";
import { connect } from "react-redux";
import { Auth } from "aws-amplify";
import { push } from "connected-react-router";
import { selectApplinkURL } from "../Reducers/CustomerDetailsReducer";
class Confirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AppLinkURL: selectApplinkURL(props.barcode?.partner),
    };
    this.AnimationContainer = React.createRef();
  }
  componentDidMount() {
    if (!this.props.userDetails) {
      this.props.dispatch(push("/subscription"));
    }

    Auth.signOut();
  }
  render() {
    return (
      <div>
        <ComponentContainerHeader
          primaryTitle={PrimaryTitles.Confirmation}
          secondaryTitle={""}
        />
        <div className={"payment-container"}>
          <img
            src={require("../Assets/registration-complete.png")}
            alt="confirmation clip board"
          />
          <p>
            It's time for our physiotherapists to help you reach your potential.
          </p>
          <p>
            Now download the Universal Practice App from the App Store or Google Play.
         </p>
          <div className='confirmation-store-icons'>
            <a href={this.state.AppLinkURL.IOS_URL} target="_blank" rel="noopener noreferrer"><img src={require('../Assets/app-store-badge.png')} alt="IOS store icon" className={'store-badges'} /></a>
            <a href={this.state.AppLinkURL.Android_URL} target="_blank" rel="noopener noreferrer"><img src={require('../Assets/google-play-badge.png')} alt="Play store icon" className={'store-badges'} /></a>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(Confirmation);
