import React from 'react'
import { CardNumberElement } from '@stripe/react-stripe-js';

const MyDNACardNumber = ({ onChange }) => {
  return (
    <div className="background">
      <CardNumberElement
        className="card-textfield"
        options={{
          iconStyle:'solid',
          placeholder: '',
          style: {
            base: {
              fontSize: '18px',
              color: '#fff',
              '::placeholder': {
                color: '#fff',
              },
            },
            invalid: {
              color: '#9e2146',
            },
          },
        }}
      />
    </div>
  )
}

export default MyDNACardNumber
