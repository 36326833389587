import React from "react";

const PaymentIcons = () => {
  return (
    <div className={"MuiFormControl-root payment-container"} >
      <div className={"payment-subcontainer"}>
        <img
          src={require("../Assets/SSLicon.png")}
          className="ssl-icon"
          alt="Logo"
        />
          <img
            src="https://d0.awsstatic.com/logos/powered-by-aws.png"
            className="aws-icon"
            alt="Powered by AWS Cloud Computing"
          />
      </div>
    </div>
  );
};

export default PaymentIcons;
