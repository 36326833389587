import React, { useState } from "react";
import { push } from "connected-react-router";
import { Formik, Form } from "formik";
import ComponentButtons from "../../Shared/ComponentButtons";
import ComponentContainerHeader from "../../Shared/ComponentContainerHeader";
import {
  subscriptionCaptureFailed,
  subscriptionCaptured,
  couponDetailsCaptured,
  couponDetailsReset
} from "../../Actions/CustomerDetailsActions";
import { SecondaryTitles } from "../../Shared/TitleTexts";
import { updateCustomerDetails } from "../../HttpHelpers/CustomerServiceHttpHelper";
import SubscriptionOption from "./SubscriptionOption";
import Coupon from "./Coupon";
import { calculateCost, calculateTimeToPay, setDefaultSubscriptionOption, verifyCouponApplication } from './SubscriptionServices'
import { connect, useDispatch } from 'react-redux';
import { getSubscriptionDetails } from "../../HttpHelpers/CustomerServiceHttpHelper";
import CheckboxInput from "../../Shared/CheckboxInput";
import { makeStyles } from "@material-ui/core/styles";
import { TermsConditionsLink } from "../../Shared/Constants";

const useStyles = makeStyles(theme => ({
  finePrintContainer: {
    marginTop: 30,
    marginBottom: 30
  }
}));

const Subscription = (props) => {
  const classes = useStyles();
  const [termsAndConditions, setTermsAndConditions] = useState(false)
  const [validationError, setValidationError] = useState("");
  const [error, setError] = useState(null)
  const [subscriptionOptions, setSubscriptionOptions] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState('')
  const [discount, setDiscount] = useState(0)
  const [successMessage, setSuccessMessage] = useState(null)
  const dispatch = useDispatch();
  React.useEffect(() => {
    getSubscriptionDetails()
      .then((response) => {
        response.json()
          .then((data) => {
            setSubscriptionOptions(setDefaultSubscriptionOption(data))
          })
      })
  }, [])

  const updateCustomerSubscription = async (store) => {
    setValidationError("");
    await updateCustomerDetails(store)
      .then((response) => {
        if (response.ok) {
          dispatch(push(props.nextPage));
          return response.json();
        }
        throw Error(response.status);
      })
      .catch((err) => {
        setValidationError(
          "There was an error updating your subscription option. Please try again."
        );
        dispatch(subscriptionCaptureFailed(err.message));
      });
  };

  const couponProcess = async (values) => {

    if (values?.coupon !== '') {
      let data = await verifyCouponApplication(values?.coupon, values?.subscriptionOptionId)
      if (data?.isValid) {
        dispatch(couponDetailsCaptured({ ...data, promoCode: values?.coupon }))
        setValidationError(null);
        setDiscount(data?.products[0].percentOff ?? 0);
        setSuccessMessage("Coupon applied successfully.");
      } else {
        setSuccessMessage(null);
        setValidationError(null);
        setValidationError("Coupon invalid, Please try again.");
        setDiscount(0)
      }
    } else {
      setSuccessMessage(null);
      setValidationError(null);
      setValidationError('Please enter coupon code.');
    }

  };

  const onOptionSelect = (value, setFieldValue) => {
    if (selectedProduct !== value) {
      dispatch(couponDetailsReset())
      setDiscount(0)
      setFieldValue('coupon', '')
      setSuccessMessage(null);
      setValidationError(null);
    }
    setSelectedProduct(value);
  }
  let defaultOption = subscriptionOptions?.find(element => element.isDefault === true)
  if (subscriptionOptions) {
    return (
      <div>
        <ComponentContainerHeader
          primaryTitle=""
          secondaryTitle={SecondaryTitles.Subscription}
          secondaryTitleLine2={SecondaryTitles.SubscriptionLine2}
        />

        <Formik
          validateOnChange={true}
          initialValues={{ subscriptionOptionId: defaultOption?.id, coupon: '', subscriptionOptionPriceId: defaultOption?.priceId }}
          onSubmit={(data, { setSubmitting }) => {
            if (termsAndConditions) {
              setSubmitting(true);
              dispatch(subscriptionCaptured(data));
              updateCustomerSubscription(props)
              setSubmitting(false);
            } else {
              setError("Please accept the terms and conditions.")
              setSubmitting(false);
            }
          }}
        >
          {({ values, isSubmitting, setFieldValue }) => (
            <Form>
              <div className='content'>
                <div className='subscription-options-container'>
                  <SubscriptionOption
                    setFieldValue={setFieldValue}
                    onSelect={(id) => onOptionSelect(id, setFieldValue)}
                    subscriptionOptionId={values.subscriptionOptionId}
                    subscriptionOptions={subscriptionOptions}
                  />
                </div>
                <div className={classes.finePrintContainer}>
                  <p>
                    All memberships offer you the first 7 days free, unrestricted access to the Universal Practice App. <br/>
                    All memberships are recurring and will automatically renew at the end of each subscription period. <br/>
                    You can unsubscribe at any time.
                  </p>
                </div>
                <Coupon
                  value={values.coupon}
                  setFieldValue={setFieldValue}
                  onClick={() => {
                    couponProcess(values)
                  }} />
                {successMessage && <p className="success">{successMessage}</p>}
                {validationError && (
                    <p className="error">{validationError}</p>
                )}
                <div className={'order-total-block'}>
                  <div className={'order-total-container'}>
                    <div className={'order-total'}>
                      <div>Your first payment of ${calculateCost(subscriptionOptions, values?.subscriptionOptionId, discount)} will occur on the {calculateTimeToPay(subscriptionOptions, values?.subscriptionOptionId, discount)}</div>
                    </div>
                  </div>
                </div>
                <br />
                <CheckboxInput
                  name={"termsAndConditions"}
                  value={termsAndConditions}
                  setFieldValue={setFieldValue}
                  onChange={(event)=>{
                    setTermsAndConditions(event.target.checked)
                    event.target.checked && setError(null)
                  }}
                />
                <span className={'terms-conditions-font'}>
                  I agree to the{" "}
                  <a
                    href={TermsConditionsLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={"TermsAndConditionsLink"}
                  >
                    Terms & Conditions
                  </a>
                </span>
                {error && <p className="error">{error}</p>}
                <ComponentButtons
                  isSubmitting={isSubmitting}
                  dispatch={dispatch}
                  singleButton={true}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  } else {
    return <h1> Loading subscription options... </h1>
  }

};

function mapStateToProps(state) {
  return state.customerDetails.initialValue
}

export default connect(mapStateToProps)(Subscription);
