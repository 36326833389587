import React from 'react'
import { CardExpiryElement } from '@stripe/react-stripe-js';

const MyDNAExpiryElement = () => {
    return (
        <div className="background">
            <CardExpiryElement
                className="card-textfield"
                options={{
                    placeholder: '',
                    style: {
                        base: {
                            fontSize: '18px',
                            color: '#fff',
                            '::placeholder': {
                                color: '#fff',
                            },
                        },
                        invalid: {
                            color: '#9e2146',
                        },
                    },
                }}
            />
        </div>
    )
}

export default MyDNAExpiryElement