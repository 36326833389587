import * as yup from "yup";

if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
    console.error('**Stripe publishable key environment variable not set**');
    console.error(
        '**Add an environemnt variable REACT_APP_STRIPE_PUBLISHABLE_KEY**'
    );
    console.error('**Replace .env.example with .env and **');
}

export const verifyPaymentMethod =(setCardNumberErrorMessage, setCvcErrorMessage, setExpiryDateErrorMessage, elements)=>{
    const cardNumberElement = elements.getElement('cardNumber');
    cardNumberElement && cardNumberElement.on('change', function (event) {
        if (!event.complete) {
            setCardNumberErrorMessage("Please check your card number!")
        } 
    });
    const cvcElement = elements.getElement('cardCvc');
    cvcElement && cvcElement.on('change', function (event) {
        if (!event.complete) {
            setCvcErrorMessage("Please check your cvc number!")
        } 
    });
    const cardExpiryElement = elements.getElement('cardExpiry');
    cardExpiryElement && cardExpiryElement.on('change', function (event) {
        if (!event.complete) {
            setExpiryDateErrorMessage("Please check your card expiry date!")
        } 
    });
}

export const initialValues = {
    firstName: "",
    lastName: "",
    addressLine1: "",
    suburbCity: "",
    country: "",
    stateProvince: "",
    postalCode: "",
};

export const validationSchema = yup.object({
    firstName: yup.string()
        .required("First Name is Required"),
    lastName: yup.string()
        .required("Last Name is Required"),
    addressLine1: yup.string()
        .required("Address is Required"),
    suburbCity: yup.string()
        .required("Suburb is Required"),
    country: yup.string()
        .required("Country is Required"),
    stateProvince: yup.string()
        .required("State is Required"),
    postalCode: yup.string()
        .required("Postcode is Required")
        .max(4, "Postcode cannot exceed 4 digits"),
});