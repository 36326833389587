import React from 'react'
import StyledButton from "../../components/StyledButton.js";
import MyDNATextInput from "../../Shared/MyDNATextInput.js";

const Coupon = ({ setFieldValue, onClick, value }) => {
    return (
        <React.Fragment>
            <div className='spaced-container'>
                <div className='coupon-input'>
                    <MyDNATextInput
                        name="coupon"
                        label="Promo code"
                        setFieldValue={setFieldValue}
                        value={value}
                    />{" "}
                </div>
                <StyledButton
                    onClick={onClick}
                    className={'couponSubmission'}
                >Apply</StyledButton>
            </div>
        </React.Fragment >

    )
}

export default Coupon
