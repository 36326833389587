import React from "react";
import { ForgotPassword } from "aws-amplify-react";
import { RegisterActions, forgotPassword } from "../Actions/RegisterActions";
import { Auth } from 'aws-amplify';
import { notify } from "../Shared/Notifier";
import { messages } from "../Shared/Messages";
import { NotificationSeverity } from "../Shared/Constants";
import { connect } from "react-redux";
import ComponentButtons from "../Shared/ComponentButtons";
import AuthenticationStepper from "../Shared/AuthenticationStepper";
import { AuthenticationTitle } from "../Shared/Constants";
import StyledTextField from "../components/StyledTextField";

class MyDnaForgotPassword extends ForgotPassword {
  constructor(props) {
    super(props);
    this._validAuthStates = [RegisterActions.FORGOT_PASSWORD];
  }

  sendVerificationEmail(event) {
    event.preventDefault();
    Auth.forgotPassword(this.inputs.email?.trim())
      .then((output) => {
        notify(messages.passwordResetCode, NotificationSeverity.SUCCESS)
        this.setState({
          errorMessage: null
        })
        this.props.dispatch(forgotPassword({ email: this.inputs.email?.trim() }));
        super.changeState(RegisterActions.REQUIRE_NEW_PASSWORD);
      }).catch((err) => {
        this.setState({
          errorMessage: messages.InvalidParameterException
        })
      });
  }

  showComponent() {
    return (
      <form onSubmit={(event) => this.sendVerificationEmail(event)} className='content content-width'>
        <AuthenticationStepper title={AuthenticationTitle.ForgotPassword.title} progress={AuthenticationTitle.ForgotPassword.progress} />
        <div>
          <StyledTextField
            id="email"
            title="Email"
            key="email"
            name="email"
            onChange={this.handleInputChange}
            type="text"
            required={true}
          />{" "}
        </div>
        {
          this.state.errorMessage && <div>
            <p className="error">{this.state.errorMessage}</p>
          </div>
        }
        <ComponentButtons dispatch={this.props.dispatch} previousPage={this.props.previousPage} singleButton={true} isSubmitting={false} submitText={"Send Verification Email"} />
      </form>

    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)((MyDnaForgotPassword))
