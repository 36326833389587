import StripePayment from "../CustomerDetails/Payment/StripePayment";
import Confirmation from "../CustomerDetails/Confirmation";
import Subscription from "../CustomerDetails/Subscription/Subscription.js";

export const PrivatePages = [
  {
    key: 2,
    path: "/subscription",
    component: Subscription,
    title: "Select your membership",
    nextPage: "/payment",
    stepNum: 1
  },
  {
    key: 3,
    path: "/payment",
    component: StripePayment,
    title: "Payment",
    nextPage: "/confirmation",
    previousPage: "/subscription",
    stepNum: 2
  },
  {
    key: 4,
    path: "/confirmation",
    component: Confirmation,
    title: "COMPLETE",
    previousPage: "/payment",
    hideInMenu: true,
    stepNum: 3
  },
];

export const AllowedPatterns = {
  AlphabetNumberDotCommaCheck: /^[A-Za-z0-9,.'-]+$/,
  AlphabetNumberDotCommaCheckWithSpace: /^[A-Za-z0-9 ,.'-]+$/,
  NumericCheck: /^\d+$/,
  NumericRangeCheck:/^[0-9]+$/,
  YearRangeCheck:/^[20-9]+$/
};

export const ErrorMessages = {
  AlphabetNumberDotCommaCheck:
    "Invalid values. The only values permitted are [A-Z 0-9 . , ' - ]",
  NumericCheck: "Invalid values. The only values permitted are [0-9]",
  AlphabetNumberDotCommaCheckWithSpace:
    "Invalid values. The only values permitted are [A-Z 0-9 . , ' - ]. You must have 2 or more words.",
};

export const NotificationSeverity = {
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
  ERROR: "error",
};

export const AuthResult = {
  SUCCESS: "SUCCESS",
};

export const SnackbarAutoHideDuration = 2000;

export const CVCPlaceHolder = "CVV/CVC";

export const AuthenticationTitle = {
  SignUp: { title: "Create your account", progress: 0 },
  VerifySignUp: { title: "VERIFY YOUR ACCOUNT", progress: 1 },
  SignIn: { title: "Create your account", progress: 0 },
  ForgotPassword: { title: "FORGOT PASSWORD", progress: 0 },
  ForgotPasswordConfirmation: { title: "CHANGE PASSWORD", progress: 0 },
};

export const ConfirmationLinks = {
  Account: "https://www.mydna.life/",
  myDNA: "https://www.mydna.life/",
};

export const PrivacyPolicyLink = "https://www.universalpracticeapp.com.au/privacy-policy/";

export const TermsConditionsLink = "https://www.universalpracticeapp.com.au/terms-conditions/";