import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import MyDNATextInput from "../../Shared/MyDNATextInput";
import { makeStyles } from '@material-ui/core';
import PaymentIcons from "../../Shared/PaymentIcons";
import { commitCustomerDetails } from "../../HttpHelpers/CustomerServiceHttpHelper";
import { paymentDetailsCaptured, paymentDetailsCaptureFailed } from "../../Actions/CustomerDetailsActions";
import { CardTypes } from "../../Shared/CardTypes";
import { SecondaryTitles } from "../../Shared/TitleTexts";
import ComponentContainerHeader from "../../Shared/ComponentContainerHeader";
import { push } from "connected-react-router";
import { Formik, Form } from "formik";
import MyDNACardNumber from "./MyDNACardNumber";
import MyDNACardCvCElement from "./MyDNACardCvCElement";
import MyDNAExpiryElement from "./MyDNAExpiryElement";
import { connect, useDispatch } from 'react-redux';
import StyledButton from "../../components/StyledButton";
import { verifyPaymentMethod, initialValues, validationSchema } from "./PaymentService";
const useStyles = makeStyles(theme => ({
    root: {
        textAlign: "center",
        display: "inline-block",
        alignItems: 'center',
        justifyContent: 'center',
        margin: theme.spacing(1)
    },
    flexContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    flexItem: {
        width: "48%"
    }
}));


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutForm = (props) => {
    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [errorToDisplay, setErrorToDisplay] = useState(null);
    const [cardNumberErrorMessage, setCardNumberErrorMessage] = useState(null)
    const [cvcErrorMessage, setCvcErrorMessage] = useState(null)
    const [expiryDateErrorMessage, setExpiryDateErrorMessage] = useState(null)
    const classes = useStyles();

    useEffect(() => {
        if (!props?.initialValue.subscription) {
            dispatch(push(props.previousPage));
        }
    }, [])



    async function createSubscription(subscriptionCode, paymentData) {
        const paymentDetails = {
            paymentMethodId: subscriptionCode,
            ...paymentData
        }
        dispatch(paymentDetailsCaptured(paymentDetails));
        commitCustomerDetails(props)
            .then(response => {
                if (!response.ok) {
                    response.json()
                        .then((data) => {
                            dispatch(paymentDetailsCaptureFailed(data));
                            setLoading(false);
                            setErrorToDisplay(data);
                        })
                } else {
                    return response.json().then(response => {
                        dispatch(push(props.nextPage))
                    })
                        .catch((err) => {
                            dispatch(paymentDetailsCaptureFailed(err.message));
                            setLoading(false);
                        });
                }
            })
    }

    const handleSubmit = async (paymentData) => {
            setLoading(true);
            const cardNumberElement = elements.getElement('cardNumber');

            // Use your card Element with other Stripe.js APIs
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardNumberElement,
            });
            if (error) {
                setLoading(false);
                return;
            }
            const subscriptionCode = paymentMethod.id;
            await createSubscription(subscriptionCode, paymentData)    
    };

    return (
        <div id="payment-form" className={classes.root}>
            <div>
                <ComponentContainerHeader
                    primaryTitle={""}
                    secondaryTitle={SecondaryTitles.Payment}
                />
                <br />
                <CardTypes />
                <Formik
                    validateOnChange={true}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(data, { setSubmitting, setFieldError }) => {
                        setSubmitting(true);
                        handleSubmit(data);
                        setSubmitting(false);
                    }}
                >
                    {({errors, values, isSubmitting, setFieldValue }) => (
                        <Form>
                            <form id="payment-form">
                                <div>
                                    <div>
                                        <MyDNATextInput
                                            name="firstName"
                                            label="First Name"
                                            maxLength="80"
                                            setFieldValue={setFieldValue}
                                            validationErrorText={errors["firstName"]?.errorText}
                                        />{" "}
                                    </div>
                                    <div>
                                        <MyDNATextInput
                                            name="lastName"
                                            label="Last Name"
                                            maxLength="80"
                                            setFieldValue={setFieldValue}
                                            validationErrorText={errors["lastName"]?.errorText}
                                        />{" "}
                                    </div>
                                    <MyDNATextInput
                                        name="addressLine1"
                                        label="Address"
                                        maxLength="80"
                                        setFieldValue={setFieldValue}
                                        validationErrorText={errors["addressLine1"]?.errorText}
                                    />{" "}
                                    <div className={classes.flexContainer}>
                                        <div className={classes.flexItem}>
                                            <MyDNATextInput
                                                name="suburbCity"
                                                label="Suburb/City"
                                                maxLength="80"
                                                setFieldValue={setFieldValue}
                                                validationErrorText={errors["suburbCity"]?.errorText}
                                            />{" "}
                                        </div>
                                        <div className={classes.flexItem}>
                                            <MyDNATextInput
                                                name="country"
                                                label="Country"
                                                maxLength="80"
                                                setFieldValue={setFieldValue}
                                                validationErrorText={errors["country"]?.errorText}
                                            />{" "}
                                        </div>
                                    </div>
                                    <div className={classes.flexContainer}>
                                        <div className={classes.flexItem}>
                                            <MyDNATextInput
                                                name="stateProvince"
                                                label="State"
                                                maxLength="80"
                                                setFieldValue={setFieldValue}
                                                validationErrorText={errors["stateProvince"]?.errorText}
                                            />{" "}
                                        </div>
                                        <div className={classes.flexItem}>
                                            <MyDNATextInput
                                                name="postalCode"
                                                label="Post Code"
                                                maxLength="80"
                                                setFieldValue={setFieldValue}
                                                validationErrorText={errors["postalCode"]?.errorText}
                                            />{" "}
                                        </div>
                                    </div>
                                    <div>
                                        <p className="input-label">{'Card Number'}</p>
                                        <MyDNACardNumber />
                                        {cardNumberErrorMessage && <p className="error small">{cardNumberErrorMessage}</p>}
                                    </div>
                                    <div className={classes.flexContainer}>
                                        <div className={classes.flexItem}>
                                            <p className="input-label">{'CVC'}</p>
                                            <MyDNACardCvCElement />
                                            {cvcErrorMessage && <p className="error small">{cvcErrorMessage}</p>}
                                        </div>
                                        <div className={classes.flexItem}>
                                            <p className="input-label">{'Expiry'}</p>
                                            <MyDNAExpiryElement />
                                            {expiryDateErrorMessage && <p className="error small">{expiryDateErrorMessage}</p>}
                                        </div>
                                    </div>
                                    <br />
                                    <PaymentIcons />
                                    <div className="inline-buttons">
                                        <StyledButton
                                            disabled={loading}
                                            className={'SubmitButton'}
                                            type="submit"
                                            value="Submit"
                                            variant="contained"
                                            color="secondary"
                                            onClick={(event) => {
                                                verifyPaymentMethod(setCardNumberErrorMessage, setCvcErrorMessage, setExpiryDateErrorMessage, elements)
                                            }}
                                        >Process Payment</StyledButton>
                                    </div>
                                    <div className="error">
                                        {errorToDisplay}
                                    </div>
                                </div>
                            </form>
                        </Form>)}
                </Formik>

            </div>
        </div >
    );
};

const PaymentForm = (props) => (
    <Elements stripe={stripePromise}>
        <CheckoutForm {...props} />
    </Elements>
);

function mapStateToProps(state) {
    return state.customerDetails.initialValue
}

export default connect(mapStateToProps)(PaymentForm);