export const CustomerDetailsActions = {
  SET_SUBSCRIPTION_PRODUCTS: "setSubscriptionProducts",
  SUBSCRIPTION_CAPTURED: "subscriptionCaptured",
  SUBSCRIPTION_CAPTURE_FAILED: "subscriptionCaptureFailed",
  PAYMENT_DETAILS: "paymentDetails",
  PAYMENT_DETAILS_CAPTURED: "paymentDetailsCaptured",
  PAYMENT_DETAILS_CAPTURED_FAILED: "paymentDetailsCapturedFailed",
  COUPON_DETAILS_CAPTURED: "couponDetailsCaptured",
  COUPON_DETAILS_RESET: "couponDetailsReset"
};


export const couponDetailsCaptured = (couponDetails) => (
  dispatch
) => {
  dispatch({
    type: CustomerDetailsActions.COUPON_DETAILS_CAPTURED,
    error: null,
    payload: {
      couponDetails: couponDetails,
    },
  });
};

export const couponDetailsReset = () => (
  dispatch
) => {
  dispatch({
    type: CustomerDetailsActions.COUPON_DETAILS_RESET,
    error: null,
    payload: {
      couponDetails: {},
      subscription: {}
    },
  });
};

export const subscriptionCaptured = (subscription) => (
  dispatch
) => {
  dispatch({
    type: CustomerDetailsActions.SUBSCRIPTION_CAPTURED,
    error: null,
    payload: {
      subscription: subscription,
    },
  });
};

export const subscriptionCaptureFailed = (error) => (
  dispatch
) => {
  dispatch({
    type: CustomerDetailsActions.SUBSCRIPTION_CAPTURE_FAILED,
    error: error,
    payload: {
      subscription: {},
    },
  });
};

export const paymentDetails = (props) => (dispatch) => {
  dispatch({
    type: CustomerDetailsActions.PAYMENT_DETAILS,
    error: null,
    payload: {
      auth: props.auth,
      subscription: props.subscription,
      payment: {},
      stepNum: 3,
    },
  });
};

export const paymentDetailsCaptured = (payment) => (dispatch) => {
  dispatch({
    type: CustomerDetailsActions.PAYMENT_DETAILS_CAPTURED,
    error: null,
    payload: {
      payment: payment,
    },
  });
};

export const paymentDetailsCaptureFailed = (error) => (dispatch) => {
  dispatch({
    type: CustomerDetailsActions.PAYMENT_DETAILS_CAPTURED_FAILED,
    error: error,
  });
};
