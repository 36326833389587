import React from "react";
import { Auth } from "aws-amplify";
import { SignIn, } from "aws-amplify-react";
import { Link } from "@material-ui/core";
import { RegisterActions, signedIn, signInFailure, confirmSignUp, confirmSignUpFailure } from "../Actions/RegisterActions";
import { notify } from "../Shared/Notifier";
import { Redirect } from "react-router";
import { messages } from "../Shared/Messages";
import { AuthenticationTitle } from "../Shared/Constants";
import ComponentContainerHeader from "../Shared/ComponentContainerHeader";
import { PrimaryTitles } from "../Shared/TitleTexts";
import ComponentButtons from "../Shared/ComponentButtons";
import AuthenticationStepper from "../Shared/AuthenticationStepper";
import StyledTextField from "../components/StyledTextField";
export class MyDnaSignIn extends SignIn {
    constructor(props) {
        super(props);
        this.state = {
            email: ""
        }
        this._validAuthStates = [RegisterActions.SIGN_IN, RegisterActions.SIGNED_OUT, RegisterActions.SIGNED_UP];
    }

    triggerAuthEvent(event) {
        notify(event.data, event.type);
    }

    logInUser(event) {
        event.preventDefault();
        const email = this.inputs.email?.trim()
        const password = this.inputs.password?.trim()
        Auth.signIn(email, password)
            .then((data) => {
                Auth.currentAuthenticatedUser().then(user => {
                    this.props.dispatch(signedIn(user));
                }).catch(e => {
                    if (e.type === "UserNotConfirmedException") {
                        this.setState({
                            errorMessage: messages.userNotConfirmedException
                        })
                        this.props.dispatch(signInFailure(messages.userNotConfirmedException));
                    }
                    this.setState({
                        errorMessage: messages.loginFailed
                    })
                    this.props.dispatch(signInFailure(messages.loginFailed));
                });
            })
            .catch((err) => {
                if (err.code === "NotAuthorizedException") {
                    this.setState({
                        errorMessage: messages.notAuthorizedException
                    })
                } else if (err.code === "UserNotConfirmedException") {
                    this.setState({
                        errorMessage: messages.userNotConfirmedException
                    })
                    this.props.dispatch(signInFailure(err.message));
                    Auth.resendSignUp(email)
                        .then(() => {
                            this.setState({
                                errorMessage: null
                            })
                            super.changeState(RegisterActions.CONFIRM_SIGN_UP, { username: email, password: password })
                            this.props.dispatch(confirmSignUp(messages.codeResent))
                        })
                        .catch(err => {
                            this.setState({
                                errorMessage: err.message
                            })
                            this.props.dispatch(confirmSignUpFailure(err.message))
                        });
                } else {
                    this.setState({
                        errorMessage: err.message
                    })
                    this.props.dispatch(signInFailure(err.message));
                }
            });
    }

    forgotPassword() {
        this.setState({
            errorMessage: null
        })
        super.changeState(RegisterActions.FORGOT_PASSWORD)
    }

    showComponent(theme) {
        return (this.props.auth.isAuthenticated ?
            <Redirect to="/subscription" /> :
            <div>
                <AuthenticationStepper title={AuthenticationTitle.SignIn.title} progress={AuthenticationTitle.SignIn.progress} />
                <form onSubmit={(event) => this.logInUser(event)}>
                    <ComponentContainerHeader primaryTitle={PrimaryTitles.SignIn} />
                    <div className='content content-width'>
                        <div>
                            <StyledTextField
                                id="email"
                                key="email"
                                name="email"
                                onChange={this.handleInputChange}
                                type="text"
                                required={true}
                            />
                        </div>
                        <div>
                            <StyledTextField
                                id="password"
                                key="password"
                                name="password"
                                onChange={this.handleInputChange}
                                type="password"
                                required={true}
                            />
                            <p className={'password-help-text'}>{messages.invalidPassword}</p>
                        </div>
                        {this.state.errorMessage && <div>
                            <p className="error">{this.state.errorMessage}</p>
                        </div>}
                        <div>
                            <ComponentButtons isSubmitting={false} dispatch={this.props.dispatch}
                                previousPage={this.props.previousPage}
                                singleButton={false}
                                submitText={'Login'}
                                backText={"Start a new regisration"}
                                disableArrows={true}
                                backOnClick={() => super.changeState(RegisterActions.SIGN_UP)} />
                            <p>
                                <Link
                                    component="button"
                                    type="button"
                                    onClick={(event) =>
                                        this.forgotPassword(event)}>
                                    <p style={{ fontSize: 18 }}>Reset your password</p>
                                </Link>
                            </p>
                        </div>
                    </div>
                </form>

            </div>
        );
    }
}
