import React from "react";
import PropTypes from "prop-types";
import MobileStepper from "@material-ui/core/MobileStepper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  progress: {
    "& *": {
      backgroundColor: "#a3f89b!important"
    }
  }
}));

const StepperComponent = (props) => {
  const classes = useStyles();
  return (
    <div>
      <MobileStepper
        style={{
          width: "20rem",
          justifyContent: "center",
          margin: "0 auto",
          backgroundColor: "black",
        }}
        classes={{progress: classes.progress}}
        variant="progress"
        steps={4}
        position="static"
        activeStep={props.stepNum}
      />
    </div>
  );
};

StepperComponent.propTypes = {
  stepNum: PropTypes.number,
};

export default StepperComponent;
