import moment from 'moment';
import { verifyCoupon } from "../../HttpHelpers/CustomerServiceHttpHelper";

export const calculateCost = (subscriptionFreightOptions, subscriptionOptionId, discount) => {
    let undiscountedPrice = subscriptionFreightOptions.find(element => element.id === subscriptionOptionId)?.subscriptionAmountPerPeriod;
    // If 100% discounted, we shou the undiscounted price - as this is what will be paid at a date in the future
    let discountedPrice = discount != 100 ? undiscountedPrice * (1 - discount/100.0) : undiscountedPrice;
    return discountedPrice;
}

export const calculateTimeToPay = (subscriptionFreightOptions, subscriptionOptionId, discount) => {
    let trialPeriodDays = subscriptionFreightOptions.find(element => element.id === subscriptionOptionId)?.trialPeriodDays
    var paymentDate = moment().add(trialPeriodDays, 'days');
    if (discount === 100) {
        let trialPeriodMonths = subscriptionFreightOptions.find(element => element.id === subscriptionOptionId)?.subscriptionInterval
        paymentDate = paymentDate.add(trialPeriodMonths, 'months');
    }
    return `${paymentDate.format('Do of MMMM YYYY')}`
}

export const setDefaultSubscriptionOption = (data) => {
    return data.map((x) => {
        x.isDefault = x.subscriptionInterval === 3;
        return x;
    });
}

export const verifyCouponApplication = async (promoCode, subscriptionCode) => {
    var data = await verifyCoupon(promoCode, subscriptionCode)
    return await data.json()
}

export const buildSubscriptionOptions = (subscriptionOptions) => {
    if (!subscriptionOptions || subscriptionOptions.length < 1) {
        return [];
    }

    const highestCostSubscription = findHighestCostSubscription(subscriptionOptions);
    const highestCostPerMth = calculateCostPerMonth(highestCostSubscription.initialChargeAmount, highestCostSubscription.subscriptionInterval);
    const subscriptions = subscriptionOptions.map((val) => {
        const costPerMth = calculateCostPerMonth(val.initialChargeAmount, val.subscriptionInterval);
        return {
        ...val,
        costPerMth: costPerMth,
        savingPercentage: calculateSavingPercentage(highestCostPerMth.value, costPerMth.value)
        }
    });
    return subscriptions;
}

const findHighestCostSubscription = (subscriptionOptions) => {
    if (!subscriptionOptions || subscriptionOptions.length < 1)
        return null

    let highestCostSubscription = subscriptionOptions[0];
    let highestCost = highestCostSubscription.initialChargeAmount / highestCostSubscription.subscriptionInterval;
    subscriptionOptions.forEach((option) => {
        const cost = option.initialChargeAmount / option.subscriptionInterval;
        if (cost > highestCost) {
            highestCostSubscription = option;
            highestCost = cost;
        }
    })
    return highestCostSubscription;
}

const calculateCostPerMonth = (cost, interval) => {
    const costPerMth = (Number(cost) / Number(interval)).toFixed(2);
    const splitDollarAndCent = costPerMth.toString().split(".");
    const dollarValue = Number(splitDollarAndCent[0]);
    const centValue = splitDollarAndCent.length > 1 ? Number(splitDollarAndCent[1]) : 0;
    return {value: costPerMth, dollarValue, centValue}
}

const calculateSavingPercentage = (highestCostPerMth, comparisonCostPerMth) => {
    const saving =  ((highestCostPerMth - comparisonCostPerMth) / highestCostPerMth) * 100;
    return saving.toFixed(0);
}